import { useQuery } from '@tanstack/react-query';
import { useTitle } from 'ahooks';
import { Card, Form, Table } from 'antd';
import { isString } from 'antd/es/button';
import { extend } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { groupBy, isNil } from 'lodash-es';
import { ColumnComponent } from '../apartment/flow/Column';
import { PlotContainer } from '../apartment/flow/PlotContainer';
import { BlurComponent } from '../apartment/myInfo/BlurComponent';
import { useGlobalManagementStore } from '../apartment/myInfo/useGlobalManagement';
import { roundFix4 } from './TransactionEdit';
import { roundFix2 } from './roundFix2';
import { StockControlForm } from './component/StockControlForm';
import { honoClient } from './honoClient';
import Nzh from 'nzh';
extend(isBetween);

export default function StockStat() {
	useTitle('库存统计');
	const { globalManagement } = useGlobalManagementStore();
	if (!globalManagement) {
		return <div>401</div>;
	}

	const [form] = Form.useForm();
	const specifications = Form.useWatch('specifications', form);
	const brand = Form.useWatch('brand', form);
	const ack = Form.useWatch('ack', form);
	const br = Form.useWatch('br#', form);
	const remark = Form.useWatch('remark', form);
	const uuid = Form.useWatch('uuid', form);
	const showCols = Form.useWatch('showCols', form);
	const stockQuery = useQuery({
		queryKey: ['stock', specifications, brand, ack, br, remark, uuid],
		queryFn: async () => {
			const res = await honoClient.stock.$post({
				json: {
					specifications,
					brand,
					ack,
					'br#': br,
					remark,
					uuid,
				},
			});

			return await res.json();
		},
	});

	const specData = Object.entries(groupBy(stockQuery?.data, 'specifications'))
		.map((i) => {
			const someIndexIsEmptyCost = i[1].find((c) => isNil(c.cost));
			const totalCost = i[1].reduce((p, c) => {
				return p + Number.parseFloat(c.cost) * Number.parseFloat(c.cubic);
			}, 0);

			return {
				specifications: i[0],
				totalCubic: roundFix2(
					i[1].reduce((p, c) => {
						return p + Number.parseFloat(c.cubic);
					}, 0),
				),
				totalCost: isNil(someIndexIsEmptyCost)
					? roundFix2(totalCost)
					: `其中库存未设置成本: ${someIndexIsEmptyCost.uuid}`,
			};
		})
		.sort((a, b) => {
			return b.totalCubic - a.totalCubic;
		});

	const specFullData = Object.entries(
		groupBy(stockQuery?.data, (i) => i.specifications.split(' ')[0]),
	)
		.map((i) => {
			const someIndexIsEmptyCost = i[1].find((c) => isNil(c.cost));
			const totalCost = i[1].reduce((p, c) => {
				return p + Number.parseFloat(c.cost) * Number.parseFloat(c.cubic);
			}, 0);

			return {
				specifications: i[0],
				totalCubic: roundFix2(
					i[1].reduce((p, c) => {
						return p + Number.parseFloat(c.cubic);
					}, 0),
				),
				totalCost: isNil(someIndexIsEmptyCost)
					? roundFix2(totalCost)
					: `其中库存未设置成本: ${someIndexIsEmptyCost.uuid}`,
			};
		})
		.sort((a, b) => {
			return b.totalCubic - a.totalCubic;
		});

	const allCubic = roundFix2(
		specData.reduce((p, c) => {
			return p + c.totalCubic;
		}, 0),
	);

	const allCost = roundFix2(
		specData.reduce((p, c) => {
			return p + (isString(c.totalCost) ? 0 : c.totalCost);
		}, 0),
	);

	return (
		<Card>
			<Form form={form}>
				<StockControlForm isShowOptions={false} />
			</Form>

			<div className="flex flex-row gap-3 my-3 flex-wrap">
				<div>总立方数</div>
				<BlurComponent>
					<div>{roundFix4(allCubic)}</div>
				</BlurComponent>
				<div>总成本(小写)</div>

				<BlurComponent>
					<div>{roundFix4(allCost)}</div>
				</BlurComponent>
				<div>总成本(大写)</div>
				<BlurComponent>
					<div>{Nzh.cn.encodeB(roundFix4(allCost), {})}</div>
				</BlurComponent>
			</div>

			<ColumnComponent
				{...{
					data: specData,
					xField: 'specifications',
					yField: 'totalCubic',
					columnWidthRatio: 0.9,
					label: {
						// 可手动配置 label 数据标签位置
						position: 'middle', // 'top', 'bottom', 'middle',
						// 配置样式
						style: {
							fill: '#FFFFFF',
							opacity: 0.6,
						},
					},
					xAxis: {
						label: {
							autoRotate: false,
						},
					},
					scrollbar: {
						type: 'horizontal',
					},
				}}
			/>

			<div className="grid grid-cols-1 my-5">
				{[
					{ title: 'specFullData', value: specFullData },
					{ title: 'specData', value: specData },
				].map((i) => {
					return (
						<PlotContainer
							key={i.title}
							{...{
								data: i.value,
								height: 450,

								angleField: 'totalCubic',
								colorField: 'specifications',
								radius: 0.75,
								appendPadding: 10,
								label: {
									type: 'spider',
									labelHeight: 28,

									style: {
										fill: '#000000',
									},
									content: '{name}\n{percentage}',
								},
								interactions: [
									{ type: 'element-selected' },
									{ type: 'element-active' },
								],
							}}
						/>
					);
				})}
			</div>

			<Table
				className="my-3"
				rowKey={specifications}
				columns={[
					{
						dataIndex: 'specifications',
						title: '规格',
					},
					{
						dataIndex: 'totalCubic',
						title: '立方数',
						sorter: (a, b) => {
							return a?.totalCubic - b?.totalCubic;
						},
					},
					{
						dataIndex: 'totalCost',
						title: '总成本',
						sorter: (a, b) => {
							if (isString(a?.totalCost)) {
								return Number.MAX_SAFE_INTEGER;
							}
							if (isString(b?.totalCost)) {
								return Number.MIN_SAFE_INTEGER;
							}

							return a?.totalCost - b?.totalCost;
						},
					},
				]}
				dataSource={specData}
			/>
		</Card>
	);
}
